<template>
  <div class="team-leaderboard">
    <div
      v-if="challenge.target_type === 3"
      class="sort"
    >
      <span class="txt">Sort by</span>
      <div class="drop-sel">
        <el-select
          v-model="meta.sort_by"
          v-loading="loading"
          placeholder="Select"
          element-loading-spinner="el-icon-loading"
          @change="handleSortChange();"
        >
          <el-option
            v-for="item in sortOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <table>
      <thead>
        <tr v-if="challenge.target_type === 1">
          <th>Team</th>
          <th>Total miles</th>
        </tr>
        <tr v-if="challenge.target_type === 2">
          <th>Team</th>
          <th>Total activities</th>
        </tr>
        <tr v-if="challenge.target_type === 3">
          <th>Team</th>
          <th>Total points</th>
          <th>Participation rate</th>
        </tr>
      </thead>
      <transition name="fade">
        <div
          v-if="!rows.length"
          v-loading="!rows.length"
          class="table-loader"
          style="min-height: 90px"
        ></div>
      </transition>
      <tbody v-if="rows">
        <tr
          v-for="(row, index) in rows"
          :key="index"
        >
          <td
            v-if="challenge.target_type === 1 || challenge.target_type === 2"
            class="text-left"
          >
            {{ row.name }}
          </td>
          <td
            v-if="challenge.target_type === 1 || challenge.target_type === 2"
            class="text-right"
          >
            {{ row.total }}
          </td>

          <td
            v-if="challenge.target_type === 3"
            class="text-left"
          >
            {{ row.team }}
          </td>
          <td
            v-if="challenge.target_type === 3"
            class="text-right"
          >
            {{ row.total_points }}
          </td>
          <td
            v-if="challenge.target_type === 3"
            class="text-right"
          >
            {{ row.participation_rate }}%
          </td>
        </tr>
      </tbody>
    </table>
    <div class="leaderboard-table-pagination">
      <el-pagination
        v-if="pagination"
        :current-page.sync="pagination.current_page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import challengeApi from '@/modules/challenge/services/challenge.api'

export default {
  name: "ChallengeTeamLeaderboard",

  props: {
    challenge: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    loading: false,
    rows: [],
    pagination: null,
    meta: {
      current_page: 1,
      per_page: 20,
      sort_by: 'total_points'
    },
    sortOptions: [
      { value: 'team', label: 'TEAM' },
      { value: 'total_points', label: 'TOTAL POINTS' },
      { value: 'participation_rate', label: 'PARTICIPATION RATE' }
    ]
  }),

  created() {
    if (this.challenge.target_type === 3) {
      this.meta.sort_by = 'participation_rate'
    }
    this.fetchLeaderboard()
  },

  methods: {
    fetchLeaderboard (customParams) {
      this.loading = true

      challengeApi
        .challenge
        .teamLeaderboard(this.challenge.id,
          _.assign({
            page: this.meta.current_page,
            per_page: this.meta.per_page,
            sort_by: this.meta.sort_by,
            sort: this.meta.sort_by == "team" ? 'asc' : 'desc'
          }, customParams)
        )
        .then(response => {
          this.loading = false
          this.rows = response.data.data
          this.pagination = response.data.meta.pagination
        })
    },
    handleSortChange(event) {
      this.fetchLeaderboard({ sort_by: event })
    },
    handleSizeChange(event) {
      this.fetchLeaderboard({ page: 1, per_page: event })
    },
    handleCurrentChange(event) {
      this.fetchLeaderboard({ page: event })
    },
  }
}
</script>
