import http from '@/services/http/app-http-driver'

export default {
  challenge: {
    index(params = {}) {
      return http({ url: '/challenges', method: 'get', params })
    },
    show(id, params = {}) {
      return http({ url: `/challenges/${id}/show`, method: 'get', params })
    },
    individualLeaderboard(id, params = {}) {
      return http({ url: `/challenges/${id}/individual-leaderboard`, method: 'get', params })
    },
    teamLeaderboard(id, params = {}) {
      return http({ url: `/challenges/${id}/leaderboard`, method: 'get', params })
    }
  }
}
